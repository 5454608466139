import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { animated, useSpring, config } from "react-spring";
import MDXRenderer from "gatsby-mdx/mdx-renderer";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { SEO, Container, Layout } from "../components";

const Title = styled.h3`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
`;

const Project = ({ data: { mdx: postNode }, location }) => {
  const project = postNode.frontmatter;

  const titleProps = useSpring({
    config: config.slow,
    from: { opacity: 0, transform: "translate3d(0, -30px, 0)" },
    to: { opacity: 1, transform: "translate3d(0, 0, 0)" }
  });

  return (
    <Layout pathname={location.pathname} customSEO>
      <SEO pathname={location.pathname} postNode={postNode} article />
      <Container type="text">
        <Title style={titleProps}>{project.title}</Title>
        <Title style={titleProps}>{project.year}</Title>
        <MDXRenderer>{postNode.code.body}</MDXRenderer>
      </Container>
    </Layout>
  );
};

export default Project;

Project.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.object.isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
};

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      code {
        body
      }
      excerpt
      fields {
        slug
      }
      parent {
        ... on File {
          mtime
          birthtime
        }
      }
      frontmatter {
        title
        year
        cover {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 800) {
              src
            }
          }
        }
      }
    }
  }
`;
